import $ from 'jquery';
import validation from 'jquery-validation';

var navHeight = $('header.stickyTop').height();
// Shrinking Nav when scrolling
$(window).on("scroll", function() {
  if($(this).scrollTop() > navHeight){
    $('.header .container').addClass('shrinked');
    $('.header .brand .brandImg').addClass('shrinked');
    $('.header .curvedPath').addClass('shrinked');
    $('.header .btnContact').addClass('shrinked');
  } else {
    $('.header .container').removeClass('shrinked');
    $('.header .brand .brandImg').removeClass('shrinked');
    $('.header .curvedPath').removeClass('shrinked');
    $('.header .btnContact').removeClass('shrinked');
  }
});

/**
 * Smooth scrolling action
 **/

$('a[href*=\\#]').on('click', function() {

  if (
    location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
    &&
    location.hostname == this.hostname
  ) {
    let hash = this.hash;
    // Figure out element to scroll to
    let target = $(hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    // Does a scroll target exist?
    if (target.length) {
      $('html, body').animate({
        scrollTop: target.offset().top - 84
      }, "fast", function() {

        // Update hash with target id values
        if(history.pushState) {
          history.pushState(null, null, hash);
        } else {
          location.hash = hash;
        }

        // Set focus on the target section
        let $target = $(target);
        $target.focus();
        if ($target.is(":focus")) { // Checking if the target was focused
          return false;
        } else {
          $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
          $target.focus(); // Set focus again
        };

      });
    }
  }
});

/**
 * USP accordion expanding action
 **/

$('#usp-accordion-btn').on('click', function() {
  let expanded = $(this).attr('aria-expanded') === 'true' || false;
  $(this).attr('aria-expanded', !expanded);
  $('.uspPanel').attr('hidden', expanded);
});


/**
 * Find your motorhome module
 **/

var GNZMS = {
  branches: [
    {
      name: 'RV Super Centre Albany - Auckland',
      northIsland: true,
      southIsland: false,
      address: '169 Bush Road, Albany, Auckland',
      phoneNumber: '',
      openDays: 'Open 6 days',
      openHoursLine1: 'Monday – Friday: 8:00am – 5:00pm',
      openHoursLine2: 'Saturday: 9:00am – 4:00pm',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581569/rvsc/gnzms/branches/RVSC-Albany-2x.jpg',
      url: 'https://www.rvsupercentre.co.nz/motorhomes-for-sale/locations/albany?pageNumber=1',
      urlLabel: 'www.rvsupercentre.co.nz'
    },
    {
      name: 'RV Super Centre - Christchurch',
      northIsland: false,
      southIsland: true,
      address: '159 Orchard Road, Harewood, Christchurch',
      phoneNumber: '',
      openDays: 'Open 7 days',
      openHoursLine1: 'Monday – Sunday: 8:00am – 4:30pm',
      openHoursLine2: '',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581571/rvsc/gnzms/branches/RVSC-christchurch-2x.jpg',
      url: 'https://www.rvsupercentre.co.nz/motorhomes-for-sale/locations/christchurch?pageNumber=1',
      urlLabel: 'www.rvsupercentre.co.nz'
    },
    {
      name: 'RV Super Centre - Queenstown',
      northIsland: false,
      southIsland: true,
      address: '50 Lucas Place, Frankton, Queenstown',
      phoneNumber: '',
      openDays: 'Open 7 days',
      openHoursLine1: 'Monday – Sunday: 9:00am – 4:30pm',
      openHoursLine2: '',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581571/rvsc/gnzms/branches/RVSC-Queenstown-2x.jpg',
      url: 'https://www.rvsupercentre.co.nz/motorhomes-for-sale/locations/queenstown?pageNumber=1',
      urlLabel: 'www.rvsupercentre.co.nz'
    },
    {
      name: 'RV Leisure - Nelson',
      northIsland: false,
      southIsland: true,
      address: '21 Elms Street, Stoke, Nelson 7011',
      phoneNumber: '',
      openDays: 'Open 6 days',
      openHoursLine1: '8:30am–5pm Monday to Friday',
      openHoursLine2: '8:30am–3pm Saturday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581569/rvsc/gnzms/branches/dealer-rv-leisure-centre-2x.jpg',
      url: 'https://rvleisurecentre.co.nz/',
      urlLabel: 'rvleisurecentre.co.nz'
    },
    {
      name: 'Autobarn RV - Invercargill',
      northIsland: false,
      southIsland: true,
      address: '189 Clyde Street, West Invercargill, Invercargill 9810',
      phoneNumber: '',
      openDays: 'Open 6 days',
      openHoursLine1: '8:30am–5:30pm Monday to Friday',
      openHoursLine2: '10:00am–2pm Saturday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581569/rvsc/gnzms/branches/dealer-autobarn-rv-2x.jpg',
      url: 'https://www.autobarn.co.nz/',
      urlLabel: 'www.autobarn.co.nz'
    },
    {
      name: 'Country RV - Tauranga',
      northIsland: true,
      southIsland: false,
      address: '36G MacDonald Street, Mount Maunganui, Tauranga 3116',
      phoneNumber: '',
      openDays: 'Open 7 days',
      openHoursLine1: '9am - 5pm Monday to Friday',
      openHoursLine2: '10am - 3pm Saturday and Sunday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581569/rvsc/gnzms/branches/dealer-country-rv-2x.jpg',
      url: 'https://www.countryrv.co.nz/',
      urlLabel: 'www.countryrv.co.nz'
    },
    {
      name: 'ANZA - Palmerston North',
      northIsland: true,
      southIsland: false,
      address: '82 Taonui Street, Palmerston North 4410',
      phoneNumber: '',
      openDays: 'Open 7 days',
      openHoursLine1: '8:30am–5:30pm Monday to Friday',
      openHoursLine2: '10:00am–5pm Saturday and Sunday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581569/rvsc/gnzms/branches/dealer-anza-motor-company-2x.jpg',
      url: 'https://www.anzamotors.co.nz/',
      urlLabel: 'www.anzamotors.co.nz'
    },
    {
      name: 'Prestige - Wellington',
      northIsland: true,
      southIsland: false,
      address: '1  Acheron Road, Paremata, Porirua 5026',
      phoneNumber: '',
      openDays: 'Open 6 days',
      openHoursLine1: '9am - 5pm Monday to Friday',
      openHoursLine2: '10am - 4pm Saturday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581569/rvsc/gnzms/branches/dealer-prestige-caravans-limited-2x.jpg',
      url: 'http://www.prestigecaravans.co.nz/',
      urlLabel: 'www.prestigecaravans.co.nz'
    },
    {
      name: 'Merit RV - New Plymouth',
      northIsland: true,
      southIsland: false,
      address: '263 Devon Street West, New Plymouth 4310',
      phoneNumber: '',
      openDays: 'Open 5 days',
      openHoursLine1: 'Monday to Friday 9am - 5pm ',
      openHoursLine2: '',
      image: 'https://res.cloudinary.com/thl/image/upload/v1598581569/rvsc/gnzms/branches/dealer-merit-rv-2x.jpg',
      url: 'http://www.meritrv.co.nz/',
      urlLabel: 'www.meritrv.co.nz'
    },
    {
      name: 'ACE Caravans & Motorhomes - Rotorua',
      northIsland: true,
      southIsland: false,
      address: '45-50 Clayton Road, Western Heights, Rotorua',
      phoneNumber: '',
      openDays: 'Open 5 days',
      openHoursLine1: '9am - 5pm Monday to Friday',
      openHoursLine2: 'Weekends by appointment',
      image: 'https://res.cloudinary.com/thl/image/upload/v1599012929/rvsc/gnzms/branches/dealer-ace-cover-2x.jpg',
      url: 'https://acecaravans.co.nz/',
      urlLabel: 'www.acecaravans.co.nz'
    },
    {
      name: 'RV Mega - Tauranga',
      northIsland: true,
      southIsland: false,
      address: '101 Hewletts Road, Mount Maunganui, Tauranga',
      phoneNumber: '',
      openDays: 'Open 7 days',
      openHoursLine1: '8am - 5pm Monday to Friday',
      openHoursLine2: '9am - 4pm Saturday and Sunday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1599090216/rvsc/gnzms/branches/dealer-rv-mega-2x.jpg',
      url: 'http://www.rvmega.co.nz/',
      urlLabel: 'www.rvmega.co.nz'
    },
    {
      name: 'Inders Marine - Gore',
      northIsland: false,
      southIsland: true,
      address: '54-56 Hokonui Drive, Gore',
      phoneNumber: '',
      openDays: 'Open 6 days',
      openHoursLine1: '8am to 5pm Monday to Friday',
      openHoursLine2: '9.30am to 12noon Saturday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1599090216/rvsc/gnzms/branches/dealer-inders-2x.jpg',
      url: 'http://www.indersmarine.co.nz/',
      urlLabel: 'www.indersmarine.co.nz'
    },
    {
      name: 'Deluxe RV Group - Blenheim',
      northIsland: false,
      southIsland: true,
      address: '45 Main Street, Blenheim',
      phoneNumber: '',
      openDays: 'Open 6 days',
      openHoursLine1: '8:00am – 5:30pm Monday to Friday',
      openHoursLine2: '9:00am - 4:00pm Saturday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1599191012/rvsc/gnzms/branches/dealer-deluxe-rv-group-branch-2x.jpg',
      url: 'https://www.deluxegroup.co.nz/',
      urlLabel: 'www.deluxegroup.co.nz'
    },
    {
      name: 'Freedom RV - Christchurch',
      northIsland: false,
      southIsland: true,
      address: '449 Ferry Road, Woolston, Christchurch',
      phoneNumber: '',
      openDays: 'Open 6 days',
      openHoursLine1: '9: 00 am - 5: 00 pm Monday to Friday',
      openHoursLine2: '10:00am - 2:00pm Saturday',
      image: 'https://res.cloudinary.com/thl/image/upload/v1599191012/rvsc/gnzms/branches/dealer-freedom-rv-branch-2x.jpg',
      url: 'https://freedomrv.co.nz/',
      urlLabel: 'freedomrv.co.nz'
    },
  ],
  vehicles: [{
      capacityLabel: "3 berth non T/S",
      capacityNumber: 3,
      manufacturer: "KEA Motorhomes",
      chassis: "Toyota",
      name: "Spirit T540",
      yearLabel: "2008-2010",
      priceRrp: "$17,990",
      priceWholesale: "N/A",
      odometer: "360-405",
      image: "https://res.cloudinary.com/thl/image/upload/v1600291017/rvsc/gnzms/Toyota-Spirit-T540-sold-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018974/rvsc/gnzms/pdf-200902b/RVSC111_KEASpiritT540_GNZMS.pdf",
      formId: "KEA Spirit T540"
    },
    {
      capacityLabel: "2 berth",
      capacityNumber: 2,
      manufacturer: "KEA Motorhomes",
      chassis: "VW",
      name: "Getaway V694",
      yearLabel: "2013",
      priceRrp: "$44,990 (save $5,000)",
      priceWholesale: "N/A",
      odometer: "225-290",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580484/rvsc/gnzms/Getaway-V694-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018979/rvsc/gnzms/pdf-200902b/RVSC111_KEAGetawayV694_GNZMS.pdf",
      formId: "KEA Getaway V694"
    },
    {
      capacityLabel: "2 berth",
      capacityNumber: 2,
      manufacturer: "United",
      chassis: "Mercedes",
      name: "Alpha 2 M600",
      yearLabel: "2013",
      priceRrp: "$49,990 (save $10,000)",
      priceWholesale: "N/A",
      odometer: "218-275",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580485/rvsc/gnzms/Alpha-2-M600-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018982/rvsc/gnzms/pdf-200902b/RVSC111_KEAAlpha2M600_GNZMS.pdf",
      formId: "United Alpha 2 M600"
    },
    {
      capacityLabel: "3 berth",
      capacityNumber: 3,
      manufacturer: "KEA Motorhomes",
      chassis: "Mercedes",
      name: "Nomad M700",
      yearLabel: "2014 - 2020",
      priceRrp: "$59,990 - $114,990",
      priceWholesale: "N/A",
      odometer: "0-265",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580484/rvsc/gnzms/Nomad-M700-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018977/rvsc/gnzms/pdf-200902b/RVSC111_KEANomadM700_GNZMS.pdf",
      formId: "KEA Nomad M700"
    },
    {
      capacityLabel: "2 berth",
      capacityNumber: 2,
      manufacturer: "KEA Motorhomes",
      chassis: "Renault",
      name: "Foxtrot R620",
      yearLabel: "2018 - 2019",
      priceRrp: "$69,990 - $77,990",
      priceWholesale: "N/A",
      odometer: "15-55",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580484/rvsc/gnzms/Foxtrot-R620-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018977/rvsc/gnzms/pdf-200902b/RVSC111_KEAFoxtrotR620_GNZMS.pdf",
      formId: "KEA Foxtrot R620"
    },
    {
      capacityLabel: "4 berth non T/S",
      capacityNumber: 4,
      manufacturer: "KEA Motorhomes",
      chassis: "Toyota",
      name: "Voyager T540",
      yearLabel: "2014 - 2016",
      priceRrp: "$39,990 - $49,990 (save $4,000)",
      priceWholesale: "N/A",
      odometer: "130-280",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580483/rvsc/gnzms/Voyager-T540-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018973/rvsc/gnzms/pdf-200902b/RVSC111_KEAVoyagerT540_GNZMS.pdf",
      formId: "KEA Voyager T540"
    },
    {
      capacityLabel: "4 berth",
      capacityNumber: 4,
      manufacturer: "KEA Motorhomes",
      chassis: "LDV",
      name: "Breeze L640",
      yearLabel: "2019",
      priceRrp: "$94,990 (save $5,000)",
      priceWholesale: "N/A",
      odometer: "20-50",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580484/rvsc/gnzms/Breeze-L640-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018982/rvsc/gnzms/pdf-200902b/RVSC111_KEABreezeL640_GNZMS.pdf",
      formId: "KEA Breeze L640"
    },
    {
      capacityLabel: "4 berth",
      capacityNumber: 4,
      manufacturer: "KEA Motorhomes",
      chassis: "VW",
      name: "Beach Rest V721",
      yearLabel: "2013 - 2014",
      priceRrp: "$64,990 - $79,990",
      priceWholesale: "N/A",
      odometer: "180-275",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580485/rvsc/gnzms/Beach-V721-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599539624/rvsc/gnzms/pdf/RVSC111_KEA_Beach_Rest_V721_GNZMS.pdf",
      formId: "KEA Beach V721"
    },
    {
      capacityLabel: "4 berth",
      capacityNumber: 4,
      manufacturer: "KEA Motorhomes",
      chassis: "Mercedes",
      name: "Beach Rest M725",
      yearLabel: "2013 - 2014",
      priceRrp: "$69,990 - $84,990",
      priceWholesale: "N/A",
      odometer: "180-275",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580485/rvsc/gnzms/Beach-Rest-M725-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018983/rvsc/gnzms/pdf-200902b/RVSC111_KEABeachRestM725_GNZMS.pdf",
      formId: "KEA Beach Rest M725"
    },
    {
      capacityLabel: "4 berth",
      capacityNumber: 4,
      manufacturer: "KEA Motorhomes",
      chassis: "Mercedes",
      name: "Breeze M660",
      yearLabel: "2016, 2019 - 2020",
      priceRrp: "$84,990 - $129,990",
      priceWholesale: "N/A",
      odometer: "0-150",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580484/rvsc/gnzms/Breeze-M660-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018980/rvsc/gnzms/pdf-200902b/RVSC111_KEABreezeM660_GNZMS.pdf",
      formId: "KEA Breeze M660"
    },
    {
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "KEA Motorhomes",
      chassis: "VW",
      name: "Horizon V710",
      yearLabel: "2013",
      priceRrp: "$74,990",
      priceWholesale: "N/A",
      odometer: "200-250",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580484/rvsc/gnzms/Horizon-V710-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018977/rvsc/gnzms/pdf-200902b/RVSC111_KEAHorizonV710_GNZMS.pdf",
      formId: "KEA Horizon V710"
    },
    {
      hidden: true,
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "KEA Motorhomes",
      chassis: "Mercedes",
      name: "Horizon M721",
      yearLabel: "2014",
      priceRrp: "$89,990",
      priceWholesale: "N/A",
      odometer: "180-230",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580484/rvsc/gnzms/Horizon-V710-1x.jpg",
      pdf: "",
      formId: "KEA Horizon M721"
    },
    {
      hidden: true,
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "KEA Motorhomes",
      chassis: "VW",
      name: "River Rest V710",
      yearLabel: "2014",
      priceRrp: "$74,990",
      priceWholesale: "N/A",
      odometer: "180-230",
      image: "",
      pdf: "",
      formId: "KEA River Rest V710"
    },
    {
      hidden: true,
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "KEA Motorhomes",
      chassis: "Mercedes",
      name: "River Rest M721",
      yearLabel: "2014",
      priceRrp: "$79,990 (save $5,000)",
      priceWholesale: "N/A",
      odometer: "180-230",
      image: "",
      pdf: "",
      formId: "KEA River Rest M721"
    },
    {
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "KEA Motorhomes",
      chassis: "Mercedes",
      name: "Platinum M721",
      yearLabel: "New",
      priceRrp: "$139,990 (save $10,000)",
      priceWholesale: "N/A",
      odometer: "0-2",
      image: "https://res.cloudinary.com/thl/image/upload/v1604957500/rvsc/gnzms/Platinum-M721-1x-sale.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018975/rvsc/gnzms/pdf-200902b/RVSC111_KEAPlatinumM721_GNZMS.pdf",
      formId: "KEA Platinum M721"
    },
    {
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "Roller Team",
      chassis: "Fiat",
      name: "Auto Roller 2590C",
      yearLabel: "2019",
      priceRrp: "$103,990",
      priceWholesale: "N/A",
      odometer: "20-45",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580485/rvsc/gnzms/Auto-Roller-2590C-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018970/rvsc/gnzms/pdf-200902b/RVSC111_RollerTeamAutoRoller2590C_GNZMS.pdf",
      formId: "Roller Team Auto Roller 2590C"
    },
    {
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "Roller Team",
      chassis: "Ford",
      name: "Zefiro 690",
      yearLabel: "2019",
      priceRrp: "$109,990",
      priceWholesale: "N/A",
      odometer: "20-45",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580483/rvsc/gnzms/Zefiro-690-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018978/rvsc/gnzms/pdf-200902b/RVSC111_RollerTeamZefiro690_GNZMS.pdf",
      formId: "Roller Team Zefiro 690"
    },
    {
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "Roller Team",
      chassis: "Ford",
      name: "Zefiro 675",
      yearLabel: "2019",
      priceRrp: "$107,990",
      priceWholesale: "N/A",
      odometer: "20-45",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580483/rvsc/gnzms/Zefiro-675-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018975/rvsc/gnzms/pdf-200902b/RVSC111_RollerTeamZefiro675_GNZMS.pdf",
      formId: "Roller Team Zefiro 675"
    },
    {
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "Roller Team",
      chassis: "Fiat",
      name: "Auto Roller 747",
      yearLabel: "2019",
      priceRrp: "$112,990",
      priceWholesale: "N/A",
      odometer: "20-45",
      image: "https://res.cloudinary.com/thl/image/upload/v1600123563/rvsc/gnzms/Auto-Roller-747-sold-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018970/rvsc/gnzms/pdf-200902b/RVSC111_RollerTeamAutoRoller747_GNZMS.pdf",
      formId: "Roller Team Auto Roller 747"
    },
    {
      capacityLabel: "4 berth",
      capacityNumber: 4,
      manufacturer: "Roller Team",
      chassis: "Fiat",
      name: "T-Line 740",
      yearLabel: "2019",
      priceRrp: "$114,990",
      priceWholesale: "N/A",
      odometer: "20-45",
      image: "https://res.cloudinary.com/thl/image/upload/v1600123563/rvsc/gnzms/T-line-740-sold-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018970/rvsc/gnzms/pdf-200902b/RVSC111_RollerTeamT-Line740_GNZMS.pdf",
      formId: "Roller Team T-Line 740"
    },
    {
      capacityLabel: "6 berth",
      capacityNumber: 6,
      manufacturer: "Roller Team",
      chassis: "Fiat",
      name: "Auto Roller 259 TL",
      yearLabel: "2019",
      priceRrp: "$104,990",
      priceWholesale: "N/A",
      odometer: "20-45",
      image: "https://res.cloudinary.com/thl/image/upload/v1598580485/rvsc/gnzms/Auto-Roller-259TL-1x.jpg",
      pdf: "https://res.cloudinary.com/thl/image/upload/v1599018982/rvsc/gnzms/pdf-200902b/RVSC111_RollerTeamAutoRoller259TL_GNZMS.pdf",
      formId: "Roller Team Auto Roller 259 TL"
    },
  ]

}

function buildBranchCardHtml(data){
  return '<div class="resultCol">' +
    '         <div class="resultCard">' +
    '             <div class="cardPreview">' +
    '                 <img src="'+data.image+'" alt="'+data.name+'">' +
    '             </div>' +
    '             <div class="cardBody">' +
    '                 <h4>'+data.name+'</h4>' +
    '                 <p>'+data.address+'</p>' +
    '                 <p>'+data.openDays+'</p>' +
    '                 <p>'+data.openHoursLine1+'<br>'+data.openHoursLine2+'</p>' +
    '                 <p>Website: <a href="'+data.url+'" target="_blank" rel="noreferrer">'+data.urlLabel+'</a></p>' +
    '                 <button data-form-id="" on="tap:contact-lightbox" class="btn btnPrimary btnEnquire"><span class="btnText">Enquire Now</span></button>' +
    '             </div>' +
    '         </div>' +
    '     </div>'
}

function buildMotorhomeCardHtml(data){
  let html = '<div class="resultCol">' +
    '         <div class="resultCard">' +
    '               <div class="cardPreview">';
    if(data.image && data.image.length) html += '<img src="'+data.image+'" alt="'+data.name+'">';
    html += '       </div>' +
    '               <div class="cardBody">' +
    '                   <h4>'+data.name+'</h4>' +
    '                   <p>Chassis: '+data.chassis+'</p>' +
    '                   <p>Berth: '+data.capacityLabel+'</p>' +
    '                   <p>Year Range: '+data.yearLabel+'</p>' +
    '                   <p style="font-size: 12px;">RV Super Centre Price Range: '+data.priceRrp+'</p>' +
    '                   <p style="padding-top: 3px;font-size: 11px;"><small><i>Pricing at partner dealerships may vary from the RV Super Centre</i></small></p>'+
    '                   <div class="btnHolder">' +
    '                   <button data-form-id="'+data.formId+'" on="tap:contact-lightbox" class="btn btnPrimary btnEnquire"><span class="btnText">Enquire Now</span></button>';


    if(data.pdf && data.pdf.length > 3) html += '<a href="'+data.pdf+'" class="externalLink" target="_blank" rel="noreferrer"><span class="btnText">View details</span></a>';

    html += '                   </div>' +
    '               </div>' +
    '         </div>' +
    '     </div>';
    return html;
}

var $fymQuestion = $('#fymQuestion'),
    $fymOptions = $('#fymOptions'),
    $fymPanel = $('#fymPanel'),
    $fymResults = $('#fymResults');

var selectedCat,
    berth,
    brand,
    island;

const livechatUrl = "https://www.messenger.com/t/greatnzmotorhomesale";

$('.fymCat').on('click', function() {
  $(this).addClass('active');

  // save category selected
  selectedCat = $(this).attr('data-cat');
  // console.log("SELECTED CAT: ", selectedCat);
  localStorage.setItem("browse", selectedCat);

  let question = "";
  let options = [];
  let htmlOptions = "";

  if (selectedCat.length && selectedCat == "model") { // when browsing models
    question = "How many berth are you looking for?";
    options = ["2", "3", "4", "6", "All berths"];
    htmlOptions = options.map(function(item) {
      return "<button class='btn btnFym'>"+item+"</button>";
    }).join('');
  }
  if (selectedCat.length && selectedCat == "loc") { // when browsing location
    question = "Where are you based in New Zealand?";
    options = ["North Island", "South Island"];
    htmlOptions = options.map(function(item) {
      return "<button class='btn btnFym'>"+item+"</button>";
    }).join('');
  }
  if (selectedCat.length && selectedCat == "unsure") { // when clicking unsure
    question = "Get expert advice through our LIVE chat or Email Us with your enquiry. Our nationwide team of passionate experts look forward to helping you find your perfect motorhome."
    htmlOptions = "<a href='"+livechatUrl+"' target='_blank' class='btn btnPrimary btnHelp'><span class='btnText'>Live Chat</span></a><button on='tap:contact-lightbox' class='btn btnSecondary btnHelp'><span class='btnText'>Drop us an email</span></button>"
  }

  // insert html contents for question and options
  $fymQuestion.html(question);
  $fymOptions.html(htmlOptions);

  $fymPanel.slideDown( "fast", function() {

    scrollTo($(this), 84);

    $('.btnFym').on('click', function() {
      $(this).addClass('active');

      if (selectedCat.length && selectedCat == "model") { // when browsing models

        // save berth
        berth = $(this).text();
        // console.log("BERTH: ", berth);
        localStorage.setItem("berth", berth);

        if(berth && berth.length !== 0) { // when the first question answered

          // set second question and options
          question = "Which brand of motorhome are you looking for?";
          options = ["KEA Motorhomes", "Roller Team", "All brands"];
          htmlOptions = options.map(function(item) {
            return "<button class='btn btnFym'>"+item+"</button>";
          }).join('');

          // replace question and option elements with fadein effects
          $fymQuestion.html(question).css('display', 'none').fadeIn("fast", function() {
            $fymOptions.html(htmlOptions).css('display', 'none').fadeIn( "fast", function() {
              $('.btnFym').on('click', function() {
                $(this).addClass('active');

                // save brand
                brand = $(this).text();
                // console.log("BRAND: ", brand);
                localStorage.setItem("brand", brand);

                disableSiblings($(this));

                // lets clear the current card container, ready for new stuff
                $('#fymResults .resultWrapper').html('');

                $fymResults.slideDown( "fast", function() {
                  $('.numShowing').html('Sorry, we dont have any matches. Would you like to start your <a href="#" id="resetSearchFromResults">search again</a>.')
                    let counter = 0;
                    for(let i=0; i<GNZMS.vehicles.length; ++i){

                      // lets check if it should be hidden
                      if(GNZMS.vehicles[i].hidden) continue;
                      // lets check if the branding matches
                      if(brand !== 'All brands' && GNZMS.vehicles[i].manufacturer !== brand) continue;
                      // lets check is the berth matches
                      if(berth !== 'All berths' && GNZMS.vehicles[i].capacityNumber !== Number(berth)) continue;
                      // it's a match, lets get some html;
                      ++counter;
                      if(counter === 1){
                        $('.numShowing').text(counter+' result for you');
                      } else {
                        $('.numShowing').text(counter+' results for you');
                      }
                      $('#fymResults .resultWrapper').append(buildMotorhomeCardHtml(GNZMS.vehicles[i]));
                    }

                    $('.btnEnquire').on('click', function() {
                      let formID = $(this).data("form-id");
                      $('#mhModel').val(formID);
                    });
                });
              });
            });
          });
        }
      }

      if (selectedCat.length && selectedCat == "loc") { // when browsing location

        // save island
        island = $(this).text();
        // console.log("ISLAND: ", island);
        localStorage.setItem("island", island);

        disableSiblings($(this))

        // lets clear the current card container, ready for new stuff
        $('#fymResults .resultWrapper').html('');

        $fymResults.slideDown( "fast", function() {
          let counterB = 0;
          for(let i=0; i<GNZMS.branches.length; ++i){
            // lets check if the island matches
            if(island === 'North Island' && !GNZMS.branches[i].northIsland) continue;
            if(island === 'South Island' && !GNZMS.branches[i].southIsland) continue;

            ++counterB;
            if(counterB === 1){
              $('.numShowing').text(counterB+' result for you');
            } else {
              $('.numShowing').text(counterB+' results for you');
            }
            $('#fymResults .resultWrapper').append(buildBranchCardHtml(GNZMS.branches[i]));
          }
        });
      }

    });
  });

  disableSiblings($(this))

});

// trigger reset
$('body').on('click','#resetSearchFromResults',  function(e){
  e.preventDefault();
  $('#fymReset').click();
});

// RESET - Start over
$('#fymReset').on('click', function() {
  selectedCat = "";
  berth = "";
  brand = "";
  island = "";

  if($('.fymCat').hasClass('active')) {
    $('.fymCat').removeClass('active');
  }
  if($('.btnFym').hasClass('active')) {
    $('.btnFym').removeClass('active');
  }

  $fymPanel.slideUp( "fast", function() {
    $('.fymCat').attr("disabled", false);
  });
  $fymResults.slideUp( "fast", function() {
    $('.btnFym').attr("disabled", false);
  });

  scrollTo($('#fym'), 84);
});

function scrollTo($target, subtract) {
    $('html, body').animate({
      scrollTop: $target.offset().top - subtract
    }, "fast", function() {
      // Set focus on the target section
      $target.focus();
      if ($target.is(":focus")) { // Checking if the target was focused
        return false;
      } else {
        $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
        $target.focus(); // Set focus again
      }
    });
}

function disableSiblings($curElm) {
  let $siblings = $curElm.siblings().not($curElm);
  if ($siblings != null && $siblings != undefined) {
    $siblings.attr("disabled", true);
  }
}


/**
 * Enquire Form
 **/

$("#enquireForm").validate({
  rules: {
    firstname: {
      required: true
    },
    lastname: {
      required: true
    },
    email: {
      required: true,
      email: true
    },
    phone: {
      required: true,
    },
    agree: {
      required: true
    }
  },
  messages: {
    firstname: {
      required: "Please enter first name.",
    },
    lastname: {
      required: "Please enter last name.",
    },
    email: {
      required: "Please enter email address.",
      email: "Please enter a valid email address.",
    },
    phone: {
      required: "Please enter phone number.",
    },
    agree: {
      required: "Please check the box if you want to proceed."
    }
  },
  errorElement : 'p',
  errorLabelContainer: '.errorMsg',
  submitHandler: function(form){
    let firstName = $('#formFirstname').val();
    let lastName = $('#formLastname').val();
    let email = $('#formEmail').val();
    let phone = $('#formPhone').val();
    let model  = $('#mhModel').val();
    let dealer = $('#mhDealer').val();
    let message = $('#formMessage').val();
    let agree = $('#agree').prop('checked');
    let optin = $('#optin').prop('checked');

    // load into marketo form fields
    $('#FirstName').val(firstName);
    $('#LastName').val(lastName);
    $('#Email').val(email);
    $('#Phone').val(phone);
    $('#rVNZVehicleModelInterested').val(model);
    $('#rVNZVehicleTrialLocation').val(dealer);
    $('#rVNZLeadEnquiryCommentLatest').val(message);
    $('#gNZMSAcceptPrivacyInformation').prop('checked', agree);
    $('#rVNZOptIn').prop('checked', optin);

    let myForm = MktoForms2.allForms()[0];
    myForm.onSuccess(function(){
      $('.hideOnFormSuccess').css('opacity', 0);
      $('#enquireForm').hide().after('<div class="successMessage" style="min-height:50vh;align-content: center;align-items: center;justify-content: center;justify-items: center;align-self: center;display: flex;"><p><b style="font-weight: 800; font-size: 20px;">Thank you so much for your enquiry.</b><br/><br/> A representative from the Great New Zealand Motorhome sale will be in touch with you shortly to discuss how we can support you with finding the right motorhome for you so you can get moving and explore New Zealand in the greatest way possible.</p></div>')
      return false;  // stop the page from reloading
    });
    myForm.submit();
  }
});


